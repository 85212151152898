import freeCodeCamp from '../images/freecodecamp.png'
import midKentCollege from '../images/mid-kent-college.jpeg'
import firstInstitution from '../images/firstInstitution.webp'
import rainhamMark from '../images/rainhammark.png'

export default [
  {
    id: '4',
    title: 'Full ACCA Qualification',
    description: 'Full ACCA Qualification',
    link: 'https://www.firstintuition.co.uk/classroom-courses/maidstone/',
    image: firstInstitution,
    institution: 'First Intuition, 89 Kings Street, Maidstone, Kent. ',
    date: 'September 2015 - September 2019',
  },
  {
    id: '3',
    title: 'Full AAT Qualification',
    description: 'Full AAT Qualification',
    link: 'https://www.midkent.ac.uk',
    image: midKentCollege,
    institution: 'Mid Kent College, Oakwood Park, Tonbridge Road',
    date: 'September 2011 – March 2014',
  },
  {
    id: '2',
    title: 'Manual bookkeeping and accounts ',
    description:
      'Manual bookkeeping and accounts – Level 1 First Class Pass (3 credits) February 2009 Manual bookkeeping and accounts – Level 2 First Class Pass (4 credits) July 2009 ',
    link: 'https://www.kentadulteducation.co.uk/',
    image: freeCodeCamp,
    institution: 'Adult Education Center',
    date: '2008-2009',
  },
  {
    id: '1',
    title: 'AS Levels',
    description:
      'Physical Education, Geography, Mathematics, Financial Studies and Classical Civilisation',
    link: 'https://www.rainhammark.com/',
    image: rainhamMark,
    institution: 'Rainham Mark Grammar School',
    date: '2012',
  },
]
