import React from 'react'
import { experiences as importedExperiences } from '../../data'
import './experiences.scss'

const Experiences = ({ title, animations, id, experiences }) => {
  if (!experiences) {
    experiences = importedExperiences
  }
  const renderList = experiences.map(
    (
      {
        order,
        description,
        companyName,
        dateFrom,
        dateTo,
        location,
        jobDescription,
      },
      index
    ) => {
      return (
        <div
          key={order}
          className={`work-experience ${
            animations === false
              ? ''
              : index % 2 === 1
              ? 'slide-in-left'
              : 'slide-in-right'
          }`}
        >
          <div className="work-experience__heading">
            <div className="work-experience__heading-title">
              {jobDescription}
            </div>
            <div className="work-experience__heading-company">
              <h2>{companyName}</h2>
              <p>{location}</p>
            </div>
            <div className="work-experience__heading-date">
              {dateFrom} to {dateTo}
            </div>
          </div>
          <div className="work-experience__description">
            <ul>
              {description.map((item) => {
                return <li key={item + '' + order}>{item}</li>
              })}
            </ul>
          </div>
        </div>
      )
    }
  )
  return (
    <div id={id} className="section">
      <h2 className="section__title">{title}</h2>
      {renderList}
    </div>
  )
}

export default Experiences
