import udemy from './images/udemy.png'
import coursera from './images/coursera.png'
import snt from './images/snt.jpeg'
import masteryGames from './images/masterygames.jpeg'
import linkedin from './images/linkedin.png'
import twilio from './images/twilio.png'
import pluralSight from './images/pluralsight.png'
import youtube from './images/youtube.png'

const edus = [
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-6a33ade5-569d-400a-94fe-ab46153aea75/',
    githubLinks: [],
    id: '53',
    title: "The Complete SQL Bootcamp: Go from Zero to Hero",
    description:
      'Undertake lots of SQL, Joins, Views, Aggregate Functions, MATH, Dates, Strings, Subqueries, Self-Joins, and more',
    courseLink:
      'https://www.udemy.com/share/101Whk3@TD_8nMV4PlSCYx1howUFtYvqgCONBdwgAOvgDsQHA-WmSSAX_sGlFIbE45bKNLWq/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'September 2024',
    videoHours: 9,
    completed: true,
    startDate: 'September 2024',
    instructor: 'Jose Portilla',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-13b43616-745d-4c3b-b02d-5e4116c34977/',
    githubLinks: [],
    id: '52',
    title: "The Complete Developers Guide to MongoDB",
    description:
      'Get to grips with mongo db',
    courseLink:
      'https://www.udemy.com/share/101Y1W3@r7f7wOvNXu2r-tFFpOKC0Ca31ouk-46GJDn3Kbx3PztArnRrUd7bfSz_VEcM1frF/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'August 2024',
    videoHours: 13,
    completed: true,
    startDate: 'August 2024',
    instructor: 'Stephen Grider',
  },
  {
    certificateLink: 'https://udemy.com/certificate/UC-d712a9ab-b736-4a66-a531-46faf25fad15/',
    githubLinks: [],
    id: '51',
    title: "Go: The Complete Developer's Guide (Golang)",
    description:
      'Master the fundamentals and advanced features of the Go Programming Language (Golang)',
    courseLink:
      'https://www.udemy.com/share/101Xzy3@jOO6wRW94V4JjD1Au9IMrn_ziFxcAf_8Tq3_1iRotTVU5T8V3yTUPpRv3FX-pY_M/',
    image: udemy,
    institution: 'Udemy',
    completedDate: '',
    videoHours: 9,
    completed: true,
    startDate: 'May 2023',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '50',
    title: 'AWS Certified Solutions Architect Associate Training SAA-C03',
    description:
      '2023 AWS Certified Solutions Architect Associate Video Course | AWS Solutions Architect Associate Practice Test included',
    courseLink:
      'https://www.udemy.com/share/101A443@3qcb-f1bs0oXzIZtyfzK-srC0fd2Jc_mIEYWIDCSaRHARLhYKkSrNCZOYtGvXGWi/',
    image: udemy,
    institution: 'Udemy',
    completedDate: '',
    videoHours: 10,
    completed: false,
    startDate: 'May 2023',
  },
  {
    certificateLink:
      'https://www.udemy.com/certificate/UC-0dff343f-3f6f-4fb6-b737-0a8ef1b4ec1b/',
    githubLinks: ['https://github.com/kristiansigston/flutter-hacker-news'],
    id: '49',
    title: "Dart and Flutter: The Complete Developer's Guide",
    description: 'Dart and Flutter course with Stephen Grider',
    courseLink:
      'https://www.udemy.com/course/dart-and-flutter-the-complete-developers-guide/',
    image: pluralSight,
    institution: 'PluralSight',
    completedDate: 'May 2023',
    videoHours: 31,
    completed: true,
    startDate: 'April 2023',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '48',
    title: 'AWS Solutions Architect',
    description: 'AWS Solutions Architect Course',
    courseLink: 'https://www.pluralsight.com',
    image: pluralSight,
    institution: 'PluralSight',
    completedDate: '',
    videoHours: 10,
    completed: false,
    startDate: 'November 2022',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '47',
    title: 'Terraform',
    description: 'Introduction to Terraform',
    courseLink: 'https://www.pluralsight.com',
    image: pluralSight,
    institution: 'PluralSight',
    githubLink: [],
    completedDate: '',
    videoHours: 10,
    completed: false,
    startDate: 'November 2022 50% complete',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-96eaeb93-b3fa-4408-bf3f-008b20870f98/',
    githubLinks: [],
    id: '46',
    title: "Redis - The complete developer's guide",
    description: 'Deep dive into redis with Stephen Grider',
    courseLink:
      'https://www.udemy.com/certificate/UC-96eaeb93-b3fa-4408-bf3f-008b20870f98/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'April 2022',
    videoHours: 18,
    completed: true,
    startDate: 'February 2022',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-a578e33d-2680-4a3e-9f8a-4ac2159e06f6/',
    githubLinks: [],
    id: '45',
    title: 'AWS Certified Cloud Practitioner - 500 Practice Questions',
    description: 'Introduction to AWS and cloud strategy ',
    courseLink:
      'https://www.udemy.com/share/101YEO3@b8ro2ISqYTkp3hPh8ljJU56FP2WYhkDcTlNEhPkDSEdDkkLpTB-VCRBddlHmOgQi/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'December 2022',
    videoHours: 20,
    completed: true,
    startDate: 'December 2022',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-a578e33d-2680-4a3e-9f8a-4ac2159e06f6/',
    githubLinks: [],
    id: '34',
    title: 'AWS Certified Cloud Practitioner - Complete NEW Course 2022',
    description: 'Introduction to AWS and cloud strategy ',
    courseLink:
      'https://www.udemy.com/share/102gku3@AGG295BrtuIcgLKwxO9lmxTfBlUPf7-rChX1FmpHwaiuei2waQs9neEwpwarfjiK/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'December 2022',
    videoHours: 10,
    completed: true,
    startDate: 'June 2022',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '40',
    title: 'Testing Next.js Applications',
    description: 'Testing Next.js Apps with Jest, Testing Library and Cypress',
    teacher: 'Bonnie Schulkin',
    courseLink:
      'https://www.udemy.com/share/102FSw3@1YejwoqvzciyGYXgrFXCxHIKnjR64rJGJxAKRjxVkahYfM9ELQWgvEqA1WbDSiVl/',
    image: udemy,
    institution: 'Udemy',
    completedDate: '',
    videoHours: 40,
    completed: false,
    startDate: 'September 2022 75% complete',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-9ed196d6-35cd-4655-b1d8-85994faeaa1f/',
    githubLinks: [],
    id: '36',
    title: 'Testing React with Jest and Testing Library',
    description: 'More testing practices with Jest and react testing library',
    teacher: 'Bonnie Schulkin',
    courseLink:
      'https://www.udemy.com/course/react-testing-library/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'September 2022',
    videoHours: 10,
    completed: true,
    startDate: 'August 2022',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '39',
    title: 'Dialogflow CX Training Practical',
    description: 'Chat bot CX Course',
    teacher: 'Youtube - Peter Fessel',
    courseLink: 'https://www.youtube.com/watch?v=vNAVbkmW-5I',
    image: youtube,
    institution: 'YouTube',
    completedDate: 'July 2022',
    videoHours: 10,
    completed: true,
    startDate: 'July 2022',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '38',
    title: 'Dialogflow CX Training',
    description: 'Chat bot CX Course',
    teacher: 'PluralSight',
    courseLink:
      'https://app.pluralsight.com/paths/skills/customer-experiences-with-contact-center-ai-dialogflow-cx',
    image: pluralSight,
    institution: 'PluralSight',
    completedDate: 'July 2022',
    videoHours: 10,
    completed: true,
    startDate: 'July 2022',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '43',
    title: 'Mastering Git',
    description: 'Understanding the flow of Git',
    teacher: 'Paolo Perrotta',
    courseLink:
      'https://app.pluralsight.com/library/courses/mastering-git/table-of-contents',
    image: pluralSight,
    institution: 'PluralSight',
    completedDate: 'May 2022',
    videoHours: 10,
    completed: true,
    startDate: 'May 2022',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '44',
    title: 'How Git Works',
    description: 'Getting under the hood of git',
    teacher: 'Paolo Perrotta',
    courseLink:
      'https://app.pluralsight.com/library/courses/how-git-works/table-of-contents',
    image: pluralSight,
    institution: 'PluralSight',
    completedDate: 'May 2022',
    videoHours: 10,
    completed: true,
    startDate: 'May 2022',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '35',
    title: 'Twilio Certification',
    description: 'Twilio Developer Certification',
    courseLink: 'https://www.twilio.com/',
    image: twilio,
    institution: 'Twilio',
    completedDate: 'February 2022',
    videoHours: 10,
    completed: true,
    startDate: 'January 2022',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '33',
    title: 'Building Modern Web Applications with Go (Golang)',
    description: 'Lots of Golang',
    courseLink:
      'https://www.udemy.com/share/1047cw3@t_mD8UO7ngNgx3btTw9S34X0re-9WjC_ZWx942lcIuyYk0nL2IdKG5UM-VwLkRRA/',
    image: udemy,
    institution: 'Udemy',
    completedDate: '',
    videoHours: 29,
    completed: false,
    startDate: 'November 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-d5c47c9a-d2df-4bb9-abf8-c37f7d2cbfe7/',
    githubLinks: [],
    id: '32',
    title: 'Next.js & React - The Complete Guide (incl. Two Paths!)',
    description: 'Lots of NextJs ',
    courseLink:
      'https://www.udemy.com/course/nextjs-react-the-complete-guide/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'March 2022',
    videoHours: 25,
    completed: true,
    startDate: 'January 2022',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-44f8e224-21d3-41f4-a1fa-6c071afde808/',
    githubLinks: [],
    id: '31',
    title: "Microfrontends with React: A Complete Developer's Guide",
    description: "Microfrontends with React: A Complete Developer's Guide",
    courseLink:
      'https://www.udemy.com/course/microfrontend-course/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'November 2021',
    videoHours: 9,
    completed: true,
    startDate: 'October 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-25eb383a-1559-4368-afee-e3f9712bd2ef/',
    githubLinks: [],
    id: '30',
    title: 'React Testing With Enzyme',
    description: 'React testing with enzyme',
    courseLink:
      'https://www.udemy.com/course/react-testing-with-jest-and-enzyme/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'November 2021',
    videoHours: 15,
    completed: true,
    startDate: 'October 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-0c79b019-8884-4735-ba1b-8e7009ce0016/',
    githubLinks: [],
    id: '29',
    title: "SQL and PostgreSQL: The Complete Developer's Guide",
    description: 'Learn all about SQL. Specifically with PostgreSQL',
    courseLink:
      'https://www.udemy.com/course/sql-and-postgresql/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'September 2021',
    videoHours: 22,
    completed: true,
    startDate: 'August 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-18a76cfd-e8a5-48ad-a8ab-702e9763a667/',
    githubLinks: [],
    id: '23',
    title: 'React and Typescript: Build a Portfolio Project',
    description: 'Build a js fiddle like app with react and typescript',
    courseLink:
      'https://www.udemy.com/course/react-and-typescript-build-a-portfolio-project/?kw=React+and+Typescript%3A+Build+a+Portfolio+Project&src=sac',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'August 2021',
    videoHours: 29,
    completed: true,
    startDate: 'July 2021',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '24',
    title: 'Javascript security essentials',
    description: 'Handling user input and other practices',
    courseLink: 'https://www.linkedin.com/learning',
    image: linkedin,
    institution: 'LinkedIn',
    completedDate: 'August 2021',
    videoHours: 10,
    completed: true,
    startDate: 'August 2021',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '25',
    title: 'Agile Development in the New World of Work',
    description: 'Agile Development',
    courseLink: 'https://www.linkedin.com/learning',
    image: linkedin,
    institution: 'LinkedIn',
    completedDate: 'August 2021',
    videoHours: 10,
    completed: true,
    startDate: 'August 2021s',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '26',
    title: 'Building Chatbots Using Google Dialogflow',
    description: 'Building helpful chatbots with dialogflow',
    courseLink: 'https://www.linkedin.com/learning',
    image: linkedin,
    institution: 'LinkedIn',
    completedDate: 'August 2021',
    videoHours: 10,
    completed: true,
    startDate: 'August 2021',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '28',
    title: 'Twilio interactive webinar',
    description: 'Using twilio quest',
    courseLink: 'https://www.twilio.com',
    image: twilio,
    institution: 'Twilio',
    completedDate: 'August 2021',
    videoHours: 10,
    completed: true,
    startDate: 'August 2021',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '27',
    title: 'React: Accessibility',
    description: 'Accessibility and react',
    courseLink: 'https://www.linkedin.com/learning',
    image: linkedin,
    institution: 'LinkedIn',
    completedDate: 'August 2021',
    videoHours: 10,
    completed: true,
    startDate: 'August 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-a7e29f67-f4a6-42e1-a9a0-692e659d2a5b/',
    githubLinks: [],
    id: '22',
    title: 'Microservices with Node JS and React',
    description: 'Microservices with Node JS and React with Stephen Grider',
    courseLink:
      'https://www.udemy.com/courses/search/?src=ukw&q=Microservices+with+Node+JS+and+React',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'July 2021',
    videoHours: 54,
    completed: true,
    startDate: 'June 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-bcff1bfd-8d07-451e-92b9-73d121bd95e9/',
    githubLinks: [],
    id: '21',
    title: 'Node JS: Advanced Concepts',
    description: 'Node JS: Advanced Concepts with Stephen Grider',
    courseLink:
      'https://www.udemy.com/course/advanced-node-for-developers/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'May 2021',
    videoHours: 16,
    completed: true,
    startDate: 'May 2021',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '20',
    title: 'Three js and TypeScript by Sean Bradley',
    description: 'Three JS with some typescript delivered by Sean Bradley',
    courseLink: 'https://www.udemy.com/share/102LFIBEUScF9SQHo=/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'Studying 40%',
    videoHours: 10,
    completed: false,
    startDate: 'May 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-0ae158b7-ce8b-4916-bcda-299d20fab821/',
    githubLinks: [],
    id: '19',
    title: 'Machine Learning with Javascript',
    description:
      'Machine Learning with Javascript Udemy course by Stephen Grider',
    courseLink:
      'https://www.udemy.com/course/machine-learning-with-javascript/?kw=Machine+Learning+with+Javascript&src=sac',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'May 2021',
    videoHours: 18,
    completed: true,
    startDate: 'April 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-c618baab-193d-4d91-b3f4-343a01f6dfd6/',
    githubLinks: [],
    id: '18',
    title: 'Advanced React and Redux',
    description: 'Advanced React and Redux Udemy course by Stephen Grider',
    courseLink:
      'https://www.udemy.com/course/react-redux-tutorial/?kw=Advanced+React+and+Redux&src=sac',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'April 2021',
    videoHours: 21,
    completed: true,
    startDate: 'March 2021',
  },

  {
    certificateLink: 'https://www.udemy.com/certificate/UC-d0fb54ba-54a8-4515-a9ce-a7a6f3baf544/',
    githubLinks: [],
    id: '16',
    title: 'GraphQL with React: The Complete Developers Guide',
    description: 'GraphQL with React Course (2017)',
    courseLink:
      'https://www.udemy.com/course/graphql-with-react-course/?kw=GraphQL+with+React%3A+The+Complete+Developers+Guide&src=sac',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'March 2021',
    videoHours: 13,
    completed: true,
    startDate: '',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-057a9e0a-054e-478d-bb05-830e715260ff/',
    githubLinks: ['https://github.com/kristiansigston/css-tutorial-udemy'],
    id: '7',
    title: 'Advanced CSS and Sass',
    description: 'CSS course',
    courseLink:
      'https://www.udemy.com/course/advanced-css-and-sass/?kw=Advanced+CSS+and+Sass&src=sac',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'March 2021',
    videoHours: 28,
    completed: true,
    startDate: 'February 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-2142780c-5a5f-4551-819f-88af5f22050d/',
    githubLinks: [],
    id: '8',
    title: 'The complete Ruby on Rails Developer',
    description: 'Ruby on Rails course',
    courseLink:
      'https://www.udemy.com/course/the-complete-ruby-on-rails-developer-course/?kw=The+complete+Ruby+on+Rails+Developer&src=sac',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'March 2021',
    videoHours: 47,
    completed: true,
    startDate: 'December 2020',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-137c5357-077c-46a6-90aa-7144cb889f0d/',
    githubLinks: [],
    id: '13',
    title: 'Server Side Rendering with React and Redux',
    description: 'React and SSR',
    courseLink:
      'https://www.udemy.com/course/server-side-rendering-with-react-and-redux/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'February 2021',
    videoHours: 8,
    completed: true,
    startDate: 'January 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-eb6310be-fa32-40eb-9552-b0d59cf99b69/',
    githubLinks: [],
    id: '11',
    title: 'Coding Interview Bootcamp. Algorithms and Data Structures',
    description: 'Algorithms and data structures',
    courseLink:
      'https://www.udemy.com/course/coding-interview-bootcamp-algorithms-and-data-structure/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'both in February 2021 && December 2022',
    videoHours: 13,
    completed: true,
    startDate: '',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-6eff91a7-8735-4e18-bfa0-e155aa48562a/',
    githubLinks: [],
    id: '9',
    title: 'Modern React with Redux',
    description: 'React and Redux course JavaScript',
    courseLink:
      'https://www.udemy.com/course/react-redux/?kw=Modern+React+with+Redux&src=sac',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'February 2021',
    videoHours: 37,
    completed: true,
    startDate: 'January 2021',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-51418427-7383-4414-b095-1009550e65fc/',
    githubLinks: [],
    id: '10',
    title: 'Modern React Native with Hooks',
    description: 'React Native and React Hooks',
    courseLink:
      'https://www.udemy.com/course/the-complete-react-native-and-redux-course/',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'January 2021',
    videoHours: 38,
    completed: true,
    startDate: 'November 2020',
  },
  {
    certificateLink: 'https://www.udemy.com/certificate/UC-8e1ac442-75f5-44ea-afa9-f7829ba6eee5/',
    githubLinks: [],
    id: '12',
    title: 'Udemy The Complete Universal Windows Platform (UWP) Course',
    description: 'Windows UWP',
    courseLink:
      'www.udemy.com',
    image: udemy,
    institution: 'Udemy',
    completedDate: 'December 2020',
    videoHours: 10,
    completed: true,
    startDate: '',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '6',
    title: 'Grid Critters',
    description: 'CSS Grid Course',
    courseLink: 'https://mastery.games/',
    image: masteryGames,
    institution: 'mastery.games',
    completedDate: 'June 2020',
    videoHours: 10,
    completed: true,
    startDate: '',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '5',
    title: 'Flexbox Zombies',
    description: 'CSS Flexbox Course',
    courseLink: 'https://mastery.games/',
    image: masteryGames,
    institution: 'mastery.games',
    completedDate: 'May 2019',
    videoHours: 10,
    completed: true,
    startDate: '',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '14',
    title: 'Machine Learning',
    description: 'Machine Learning with Andrew Ng from Sfanford University',
    courseLink:
      'https://www.coursera.org/learn/machine-learning?utm_source=gg&utm_medium=sem&utm_campaign=07-StanfordML-ROW&utm_content=07-StanfordML-ROW&campaignid=2070742271&adgroupid=80109820241&device=c&keyword=machine%20learning%20mooc&matchtype=b&network=g&devicemodel=&adpostion=&creativeid=369041663186&hide_mobile_promo&gclid=CjwKCAiAkJKCBhAyEiwAKQBCkvjo_sADv93MNGNybYq9QygfOB9D2X5AWYEiXbniMw3TExMK0eN3VxoCaAAQAvD_BwE',
    image: coursera,
    institution: 'Coursera',
    completedDate: 'January 2019',
    videoHours: 10,
    completed: true,
    startDate: '',
  },

  {
    certificateLink: '',
    githubLinks: [],
    id: '15',
    title: 'Total IPV6 for Engineers',
    description: 'IPV6 3 day course',
    courseLink: 'https://www.snt.co.uk',
    image: snt,
    institution: 'Systems Network Training',
    completedDate: 'March 2018',
    videoHours: 10,
    completed: true,
    startDate: '',
  },
  {
    certificateLink: '',
    githubLinks: [],
    id: '41',
    title: 'Secure Account Management Fundamentals by Troy Hunt',
    description:
      'Secure Account Management Fundamentals is all about exploiting then protecting security weaknesses in the features we often take for granted in websites today such as registration, logon, changing account info, and logoff.',
    courseLink:
      'https://app.pluralsight.com/library/courses/secure-account-management-fundamentals/table-of-contents',
    image: pluralSight,
    institution: 'PluralSight',
    completedDate: 'December 2017',
    videoHours: 10,
    completed: true,
    startDate: '',
  },
  {
    certificateLink: '',
    id: '42',
    title: 'Hack Yourself First: How to go on the Cyber-Offense',
    description:
      '"Hack Yourself First" is all about developers building up cyber-offense skills and proactively seeking out security vulnerabilities in their own websites before an attacker does.',
    courseLink:
      'https://app.pluralsight.com/library/courses/secure-account-management-fundamentals/table-of-contents',
    image: pluralSight,
    institution: 'PluralSight',
    completedDate: '2017',
    videoHours: 10,
    completed: true,
    startDate: '',
  },
]

export default edus
