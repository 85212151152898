const experiences = [
  {
    jobDescription: '(Contract/FullTime) FullStack Developer (Remote)',
    order: '2',
    description: [
      "Worked closely with HomeServe UK's Contact Center Automation Team to automate customer claims and boiler services, increasing automation from 12% to 24% and 12% to 30% respectively, significantly reducing costs.",
      'Provided weekly strategic updates to the PS team head on client direction and project progress and led fortnightly show-and-tell sessions for the CCA team, demonstrating innovative Proof of Concept features and their potential business benefits to drive improvements for the client and other Sabio clients.',
      'Improved customer journey analysis through comprehensive logging and determined optimal feature directions, refactors, and Proof of Concepts (POCs) during strategic client discussions in sprint planning. Addressed critical project blockers and ensured smooth progress during daily Agile standups. Enhanced customer and API interactions through monthly bug tracking, error detection, and UI flow analysis with fellow developers. These efforts led to better Average Handling Time (AHT) and First Contact Resolution (FCR), ultimately reducing costs and improving customer satisfaction.',
      'Implemented a robust Disaster Recovery solution using Twilio, ensuring service continuity with a backup software phone number system during outages.',
      'Created a successful Proof of Concept (POC) SMS version of the bot that efficiently booked boiler services, further reducing costs beyond those already achieved with voice bot interactions.',
    ],
    // Make the above only 5 bullet points
    dateFrom: 'July 2021',
    dateTo: 'August 2024',
    location: 'Southwark, London',
    companyName: 'Sabio',
  },
  {
    jobDescription: 'Software Developer (Remote)',
    order: '1',
    description: [
      'Utilized advanced programming skills in Node.js, React, JavaScript, Ruby on Rails, and version control with Git to develop sophisticated web applications, resulting in robust and scalable solutions. Integrated Rails Hotwire and Stimulus frameworks to optimize performance and responsiveness, improving load times and user experience.',
      'Employed Agile methodology to streamline project management and enhance team collaboration, leading to more efficient workflows and timely project deliveries. Engaged in pair programming for effective troubleshooting and feature spiking, boosting team productivity and code quality.',
      'Improved front-end layout by modifying back-end Rails, HAML, HTML, CSS, and JavaScript components, resulting in a seamless user experience. Designed a React app utility API to extract data and implement an auto-annotating tool, streamlining data processing tasks and increasing efficiency.',
      'Conducted rigorous code testing using Mocha, Chai, Sinon, Jest, and RSpec, ensuring high software quality and reliability. Collaborated closely with fellow engineers to efficiently implement new features and optimize existing ones, driving continuous improvement and ensuring the application met evolving user needs.',
      'Innovated new interactive activities to enhance user engagement and overall satisfaction, increasing user retention and interaction rates. Improved customer and API interactions through monthly bug tracking, error detection, and UI flow analysis with fellow developers.',
    ],
    dateFrom: 'December 2018',
    dateTo: 'July 2021',
    location: 'Shoreditch, London',
    companyName: 'ReallyEnglish',
  },
]

export default experiences
