// import books from '../../data/books'
import Experiences from '../experiences'
// import courses from '../../data/courses'
import Educations from '../educations'
import certs from '../../data/certifications/db-certifications'
// import Technologies from '../technologies'
// import Projects from '../projects'
// import Interests from '../interests'
// import Social from '../social'
// import Courses from '../courses'
// import BlogPosts from '../blog_posts'
// import Books from '../books'
import AboutMeDane from '../about-me-dane'
import Introduction from '../introduction/introduction-DB'
// import Podcasts from '../podcasts'
import './about-me.scss'
import dbExperiences from '../../data/experiences/dbExperiences'

const CVCompleteDB = () => {
  return (
    <div id="main" className="app">
      <div
        className="main-page"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <div className="about-me">
          <AboutMeDane />
        </div>

        <div className="main-grid">
          <h1 style={{ textAlign: 'center' }} className="title">
            Dane Beard
          </h1>
          <Introduction title="Introduction" id="about-me" />
          <Experiences
            title="Work Experience"
            id="work-experience"
            experiences={dbExperiences}
          />
          {/* <Technologies title="Languages and Technology" id="languages-tech" /> */}
          <Educations title="Education" data={certs} id="educations" />
          {/* <Courses title="Courses" data={courses} id="courses" /> */}
          {/* <Educations title="Courses" data={courses} id="courses" /> */}
          {/* <Projects title="Projects" id="projects" /> */}
          {/* <BlogPosts title="Blog Posts" id="blog-posts" /> */}
          {/* <Podcasts title="My Favourite Podcasts" id="podcasts" /> */}
          {/* <Interests title="Interests" id="interests" /> */}
          {/* <Social title="Social" id="social" /> */}
          {/* <Books
            title="Non-Fiction Books"
            books={books.nonFictionBooks}
            id="non-fiction-books"
          />
          <Books
            title="Fiction Book Novels"
            books={books.fictionSingles}
            id="fiction-books"
          />
          <Books
            extraClass="series"
            title="Fiction Book Series"
            books={books.fictionSeries}
            id="fiction-books-series"
          /> */}
        </div>
      </div>
    </div>
  )
}

export default CVCompleteDB
